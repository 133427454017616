// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-fan-favourite-js": () => import("/src/src/pages/fan_favourite.js" /* webpackChunkName: "component---src-pages-fan-favourite-js" */),
  "component---src-pages-index-previous-js": () => import("/src/src/pages/index_previous.js" /* webpackChunkName: "component---src-pages-index-previous-js" */),
  "component---src-pages-index-js": () => import("/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/src/.cache/data.json")

